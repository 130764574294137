import { Reducer } from 'redux';
import { MigrationManifest, createMigrate, createTransform, persistCombineReducers, persistReducer } from 'redux-persist';
import storage from '../services/storage';

import app, { AppState } from './app/app.reducer';
import auth, { AuthState } from './auth/auth.reducer';
import forms, { FormState, FormsState } from './forms/forms.reducer';
import formsSupport, { FormsSupportState } from './formsSupport/formsSupport.reducer';
import map, { MapState } from './map/map.reducer';
import offlineMaps, { OfflineMapsState } from './offlineMaps/offlineMaps.reducer';
import records, { RecordsState } from './records/records.reducer';

export interface ApplicationState {
  auth: AuthState;
  app: AppState;
  forms: FormsState;
  formsSupport: FormsSupportState;
  records: RecordsState;
  map: MapState;
  offlineMaps: OfflineMapsState;
};

const appMigrations: MigrationManifest = {
  0: (state: any) => {
    const { allApps, appsListSegment, ...rest } = state;
    return {
      ...rest
    }
  }
};

const recordsMigrations: MigrationManifest = {
  0: (state: any) => {
    const { geometryRecordsCount, pending, record, recordIsCached, records, recordsCount, recordsLoading, recordsMapId, recordsMapLoading, ...rest } = state;
    return {
      ...rest
    };
  }
}

// We create these 3 separate reducers to allow us to blacklist
// individual keys within the state
const appReducer = persistReducer({
  blacklist: ['ready', 'app', 'allApps'],
  key: 'app',
  storage,
  version: -1,
  migrate: createMigrate(appMigrations, { debug: true })
}, app);

const authReducer = persistReducer({
  key: 'auth',
  blacklist: ['authError', 'authInProgress', 'logoutError', 'logoutInProgress'],
  storage
}, auth);

const recordsReducer = persistReducer({
  whitelist: ['pending'],
  key: 'records',
  storage,
  version: -1,
  migrate: createMigrate(recordsMigrations, { debug: true })
}, records);

const FormsTransform = createTransform(
  (inboundState: FormState[]) => {
    return inboundState.map(({ id, ready, dirty, record, uuid, finished, sectionId, nextSectionId, prevSectionId, subformIdx, subformAttributeId }) => ({
      id,
      ready,
      dirty,
      record,
      uuid,
      sectionId,
      nextSectionId,
      finished,
      prevSectionId,
      subformIdx,
      subformAttributeId
    }));
  },
  (outboundState: FormState[]) => outboundState,
  { whitelist: ['forms'] }
)

const formsReducer = persistReducer({
  key: 'forms',
  storage,
  version: -1,
  transforms: [FormsTransform]
}, forms);

const rootReducer: Reducer<ApplicationState> = persistCombineReducers<ApplicationState>({
  key: 'root',
  storage,
  // Blacklist the standalone reducers (above) plus forms
  blacklist: ['app', 'records', 'auth', 'forms']
}, {
  auth: authReducer,
  forms: formsReducer,
  formsSupport,
  offlineMaps,
  app: appReducer,
  records: recordsReducer,
  map
});

export default rootReducer;
